/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:2c42c25e-9637-4519-83df-6be6a0e6d3c0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_E08Obg06K",
    "aws_user_pools_web_client_id": "5m8ni3h01uibt65ufbqp04b8r2",
    "oauth": {
        "domain": "mitchine-production.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.mitchine.com/,https://test.mitchine.com/,https://dev.mitchine.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.mitchine.com/,https://test.mitchine.com/,https://dev.mitchine.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_content_delivery_bucket": "mitchine-production",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://mitchine-production.s3-website-us-west-2.amazonaws.com",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "configurations-production",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "configurations",
            "endpoint": "https://wppgbw3u7k.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
