import React, { Component } from "react";

import "./Settings.css";

class Settings extends Component {
  constructor(props) {
    super(props);
    console.log("settings props", props);
    this.state = {
      name: this.props.configuration ? this.props.configuration.name : "",
      thing_name: this.props.configuration
        ? this.props.configuration.thing_name
        : "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.props.saveSettings(this.state);
    event.preventDefault();
  }

  render() {
    return (
      <div className="container" id={"settings"}>
        <div className="row">
          <div className="column">
            <h2>Settings</h2>
            <form onSubmit={this.handleSubmit}>
              <label>
                Your name is
                <input
                  id="name"
                  name="name"
                  value={this.state.name || ""}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <label>
                and your device id is
                <input
                  id="thing_name"
                  name="thing_name"
                  value={this.state.thing_name || ""}
                  onChange={this.handleChange}
                />
              </label>
              <br />
              <br />
              <input type="submit" className="waves-effect waves-light btn" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
