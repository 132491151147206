import React from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    if (this.props.loading) {
      return <></>;
    } else {
      return (
        <Route
          {...rest}
          render={props => (
            <div className={"container"}>
              {!this.props.user && (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: this.props.location }
                  }}
                />
              )}
              <Component {...this.props} />
            </div>
          )}
        />
      );
    }
  }
}

export default PrivateRoute;
