import React, { Component } from "react";
import Display from "../display/Display";

import "./Home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.controls = {};
  }

  render() {
    return (
      <div className="container" id={"home"}>
        <div className="image_header">
          <img src="/mitchine.png" alt="mitchine" />
          <h1>mitchine</h1>
        </div>
        <Display
          deviceState={this.props.deviceState}
          webSettings={this.props.webSettings}
          shadowState={this.props.deviceShadow}
        />
      </div>
    );
  }
}

export default Home;
