import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import M from "materialize-css";
import "./MainMenu.css";

class MainMenu extends Component {
  componentDidUpdate() {
    var elems = document.querySelectorAll(".fixed-action-btn");
    M.FloatingActionButton.init(elems, {});
  }

  render() {
    const { domain, redirectSignIn, responseType } = this.props.config.oauth;
    const clientId = this.props.config.aws_user_pools_web_client_id;

    const url_to_google =
      "https://" +
      domain +
      "/oauth2/authorize?redirect_uri=" +
      redirectSignIn.replace(/\/$/, "") +
      "/" +
      "&response_type=" +
      responseType +
      "&client_id=" +
      clientId +
      "&identity_provider=Google";

    if (this.props.loading) {
      return <div className="fixed-action-btn"></div>;
    }

    if (this.props.user) {
      return (
        <div className="fixed-action-btn">
          <button className="btn-floating btn-large darken-3 red">
            <i className="large material-icons">menu</i>
          </button>
          <ul>
            <li>
              <Link className="btn-floating blue" to="/">
                <i className="material-icons">toys</i>
              </Link>
            </li>
            <li>
              <Link className="btn-floating yellow darken-1" to="/settings">
                <i className="material-icons">edit</i>
              </Link>
            </li>
            <li>
              <a
                className="btn-floating green"
                href="#!"
                onClick={() => Auth.signOut()}
              >
                <i className="material-icons">logout</i>
              </a>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="fixed-action-btn">
          <button className="btn-floating btn-large darken-3 red">
            <i className="large material-icons">menu</i>
          </button>
          <ul>
            <li>
              <Link className="btn-floating blue" to="/">
                <i className="material-icons">toys</i>
              </Link>
            </li>
            <li>
              <a className="btn-floating green" href={url_to_google}>
                <i className="material-icons">exit_to_app</i>
              </a>
            </li>
          </ul>
        </div>
      );
    }
  }
}

export default MainMenu;
