import React from "react";

class Indicator extends React.Component {
  render() {
    if (!!this.props.empty) {
      return (
        <li className={`indicator empty`}>
          <div>&nbsp;</div>
        </li>
      );
    }

    const presenceClass =
      this.props.distance && this.props.distance > 0 ? "there" : "gone";
    const className = `indicator ${presenceClass} distance-${this.props.distance}`;
    const imageSrc = this.props.profileLocation
      ? this.props.profileLocation
      : "./mi_profile.png";

    return (
      <li className={className}>
        <div>
          <img src={imageSrc} alt="profile" />
        </div>
      </li>
    );
  }
}

export default Indicator;
