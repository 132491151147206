import awsmobile from "./aws-exports";

const config = awsmobile;
config.iot_config = {
  aws_pubsub_region: "us-west-2",
  aws_pubsub_endpoint:
    "wss://a1dlohijcikj7d-ats.iot.us-west-2.amazonaws.com/mqtt",
  thing_group: 'mitchell_family'
};

const { host } = window.location;

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (awsmobile.oauth.redirectSignIn.includes(",")) {
  const filterHost = (url) => new URL(url).host === host;
  awsmobile.oauth.redirectSignIn = awsmobile.oauth.redirectSignIn
    .split(",")
    .filter(filterHost)
    .shift();
  awsmobile.oauth.redirectSignOut = awsmobile.oauth.redirectSignOut
    .split(",")
    .filter(filterHost)
    .shift();

  // console.log("setting awsmobile.oauth.redirectSignIn to " + awsmobile.oauth.redirectSignIn);
  // console.log("setting awsmobile.oauth.redirectSignOut to " + awsmobile.oauth.redirectSignOut);
}

export default config;
