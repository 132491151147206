import Amplify, { Auth, Hub, PubSub, Logger, API } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import config from "./config";
import { withOAuth } from "aws-amplify-react"; // or 'aws-amplify-react-native';
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import M from "materialize-css";

import Home from "./components/pages/Home.js";
import Settings from "./components/pages/Settings";
import MainMenu from "./components/menu/MainMenu";

import RandomDeviceStateGenerator from "./RandomDeviceStateGenerator";
import webSettings from "./webSettings.json";
import deviceShadow from "./deviceShadow.json";

import "./App.scss";

Logger.LOG_LEVEL = "INFO";

Amplify.configure(config);
Amplify.addPluggable(new AWSIoTProvider(config.iot_config));
class App extends Component {
  state = {
    user: null,
    configuration: null,
    devices: {},
    loading: true,
    deviceState: {},
    deviceShadow: {},
    webSettings: {},
  };
  logger = new Logger("App");

  displayMessage(message, error = false) {
    let classes = "";
    if (error) {
      classes += " red darken-3";
    }
    M.toast({ html: message, classes: classes, displayLength: 6000 });
  }

  async componentDidMount() {
    this.logger.info("component did mount.");
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.logger.info("signed in");
          this.logger.info(data);
          this.setState({ user: data });
          this.setupAppForUser(data);
          break;
        case "signOut":
          this.logger.info("signed out.");
          this.setState({ user: null });
          break;
        case "signIn_failure":
          var urlParams = new URLSearchParams(window.location.search);
          const signInMessage = urlParams.has("error_description")
            ? urlParams.get("error_description")
            : "An error happened during sign-in.";
          this.displayMessage(signInMessage, true);
          break;
        default:
          break;
      }
    });

    try {
      let user = null;
      try {
        this.logger.info("getting current authenticated user's information");
        user = await Auth.currentAuthenticatedUser();
        this.logger.info(
          "current authenticated user's information was fetched",
          user
        );
      } catch (e) {
        this.logger.info("unable to fetch current authenticated user", e);
        this.setState({ loading: false });
      }
      this.setState({ user: user, loading: false });
      this.logger.info("setting up application state for the user");

      if (!user) {
        const generator = new RandomDeviceStateGenerator();
        this.setState({ deviceShadow: deviceShadow, webSettings: webSettings });
        setInterval(() => {
          const newDeviceState = generator.generateRandomState(
            9,
            "thing_",
            this.state.deviceState,
            this.state.deviceShadow.deviceName
          );
          this.setState(
            (previousState) => (previousState["deviceState"] = newDeviceState)
          );
        }, 2000);
        return;
      }

      try {
        await this.setupAppForUser(user);
      } catch (error) {
        let message =
          "There was a problem setting things up - try refreshing the page or contact support";
        if (error && error.message) {
          message = error.message;
        } else if (error) {
          message = error;
        }
        this.displayMessage(message, true);
      }

      this.setState({ loading: false });
    } catch (e) {
      this.logger.error("Not signed in");
    }
  }

  async setupAppForUser(user) {
    this.logger.info("fetching user configuration from API backend");
    const configuration = await this.fetchUserConfiguration(user);
    if (configuration == null) {
      this.logger.error("There was an error fetching your configuration.");
    } else {
      this.logger.info("User configuration was retrieved", configuration);
      this.setState({ configuration: configuration });
      if (configuration.thing_name) {
        this.logger.info(
          "The configuration includes a 'thing_name' so we will now setup the subscription"
        );
        try {
          this.setupDeviceSubscription(configuration);
        } catch (err) {
          this.logger.error("Error while setting up device subscription");
        }
      } else {
        this.logger.info(
          "No 'thing_name' has been set, so we will navigate to the settings tab"
        );
      }
    }
  }

  setupDeviceSubscription(configuration) {
    this.logger.info(
      `Setting up device subscription for thing name ${configuration.thing_name}`
    );

    PubSub.subscribe([
      `$aws/things/${configuration.thing_name}/shadow/update/documents`,
      `$aws/things/${configuration.thing_name}/shadow/get/accepted`,
    ]).subscribe({
      next: (data) => {
        //we are subscribing to two different events, and the shape of the data is slightly different depending
        //the update/documents event is when the shadow is updated, the get/accepted is the result of the shadow/get
        //message we send below, to request the current shadow on load
        const shadow =
          data.value && data.value.current
            ? data.value.current.state.reported
            : data.value.state.reported;
        this.setState({ deviceShadow: shadow });
        this.logger.info("the data:", data);
      },
      error: (error) => this.logger.error(error),
      close: () => this.logger.info("Done"),
    });

    //we'll pause a second and then send a request to get the thing's shadow
    setTimeout(
      () =>
        PubSub.publish(
          `$aws/things/${configuration.thing_name}/shadow/get`,
          {}
        ),
      2000
    );
    setTimeout(
      () =>
        PubSub.publish(`${config.iot_config.thing_group}/device/updates`, {
          key: configuration.thing_name,
          distance: 150,
        }),
      2000
    );

    //this is probably stupid overkill, but in theory we may need to send a "ping" / keep alive ever ~20 minutes
    setInterval(
      () =>
        PubSub.publish(
          `$aws/things/${configuration.thing_name}/shadow/get`,
          {}
        ),
      900000
    );

    PubSub.subscribe(
      `${config.iot_config.thing_group}/device/updates`
    ).subscribe({
      next: (data) => {
        this.logger.info("the in device shadow is:", data);

        this.setState((previousState) => {
          this.logger.info("the prevoius state is", previousState);
          const newState = { ...previousState };
          newState.devices[data.value.key] = data.value.distance;
          return newState;
        });
      },
      error: (error) => this.logger.error(error),
      close: () => this.logger.info("Done"),
    });
  }

  async fetchUserConfiguration(user) {
    try {
      const configurationPathForUser = "/configurations/mine";
      const response = await API.get(
        "configurations",
        configurationPathForUser,
        {}
      );
      if (response && response.length === 1) {
        return response[0];
      } else if (response && response.length === 0) {
        throw new Error(
          "There should have been exactly one configurations returned, however there were none."
        );
      }
    } catch (error) {
      this.logger.error("Error while fetching user configuration", error);
    }
    return null;
  }

  async updateUserConfiguration(updateRequest) {
    const configurationPathForUser = "/configurations";

    const updateData = {
      thing_name: updateRequest.thing_name,
      name: updateRequest.name,
    };
    let putBody = {
      body: updateData,
    };
    this.logger.info("update payload is", putBody);
    const response = await API.put(
      "configurations",
      configurationPathForUser,
      putBody
    );

    if (response.error) {
      this.logger.error(response);
      this.displayMessage(response.message, true);
    }

    this.logger.info(response);
  }

  async onSaveSettings(settingsToSave) {
    this.logger.info("saving settings...", settingsToSave);
    try {
      await this.updateUserConfiguration(settingsToSave);
    } catch (error) {
      this.logger.error(error);
      this.logger.error(error.message);
      this.displayMessage(error, true);
    }
  }

  notifyIoTOfStatus(distance) {
    PubSub.publish("$aws/things/testwebthing/shadow/update", {
      state: { reported: { distance: distance } },
    });
  }

  render() {
    return (
      <div className="container">
        <Router>
          <div className="container">
            <MainMenu
              user={this.state.user}
              loading={this.state.loading}
              config={config}
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Home
                  {...props}
                  className={"container"}
                  user={this.state.user}
                  loading={this.state.loading}
                  deviceState={this.state.deviceState}
                  webSettings={this.state.webSettings}
                  deviceShadow={this.state.deviceShadow}
                  devices={this.state.devices}
                  notifyIoTOfStatus={this.notifyIoTOfStatus.bind(this)}
                />
              )}
            />
            <PrivateRoute
              user={this.state.user}
              className={"container"}
              loading={this.state.loading}
              path="/settings"
              component={Settings}
              configuration={this.state.configuration}
              saveSettings={this.onSaveSettings.bind(this)}
            />
          </div>
        </Router>
      </div>
    );
  }
}

export default withOAuth(App);
