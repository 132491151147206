class RandomDeviceStateGenerator {
  generateRandomState = (
    positionsToGenerate,
    thingPrefix,
    previousThingState,
    thisDeviceName
  ) => {
    let newState = {};

    for (let position = 1; position <= positionsToGenerate; position++) {
      //if the current state is undefined, it's more likely the user won't randomly show up so we'll make it likely they aren't there
      if (previousThingState[`${thingPrefix}${position}`] === undefined) {
        newState[`${thingPrefix}${position}`] = this.shouldDo(7)
          ? this.randomDistance(12)
          : 2400;
        continue;
      }

      //if the currently out of range, then we'll be more likely to keep this position
      if (previousThingState[`${thingPrefix}${position}`] > 2000) {
        newState[`${thingPrefix}${position}`] = this.shouldDo(30)
          ? this.randomDistance(12)
          : 2400;
        continue;
      }

      //other wise we'll either keep the current position or move back or forth 1-2 units, or leave
      const change = Math.floor(Math.random() * 12) + 1;
      const currentDistance = previousThingState[`${thingPrefix}${position}`];
      if (change === 1) {
        newState[`${thingPrefix}${position}`] = Math.max(
          0,
          currentDistance - this.randomDistance(2)
        );
        continue;
      }
      if (change === 2) {
        newState[`${thingPrefix}${position}`] = Math.min(
          1999,
          currentDistance + this.randomDistance(2)
        );
        continue;
      }
      if (change === 3) {
        newState[`${thingPrefix}${position}`] = 2400;
        continue;
      }

      newState[`${thingPrefix}${position}`] = currentDistance;
    }

    newState[thisDeviceName] = 1;
    return newState;
  };

  randomDistance(potentialStep) {
    return (Math.floor(Math.random() * potentialStep) + 1) * 175;
  }

  shouldDo(outOfChances) {
    return Math.floor(Math.random() * outOfChances) + 1 === 1;
  }
}

export default RandomDeviceStateGenerator;
