import React from "react";
import Indicator from "./Indicator";
import "./Display.scss";

class Display extends React.Component {
  render() {
    let positions = [];

    if (!!this.props.shadowState && !!this.props.shadowState.positions) {
      positions = this.props.shadowState.positions;
    }

    return (
      <ul className="display">
        {positions.map((item, index) => {
          let empty = true;
          let distance = "";
          let profileLocation = "";
          let deviceName = !!item ? item : Math.floor(Math.random() * 10000);
          if (!!item) {
            empty = false;
            for (const key of Object.keys(this.props.deviceState)) {
              if (key === item) {
                const distanceInMM = this.props.deviceState[key];
                if (
                  distanceInMM !== null &&
                  distanceInMM !== "" &&
                  distanceInMM < 2000
                ) {
                  distance = Math.floor(this.props.deviceState[key] / 175) + 1;
                }
                break;
              }
            }
            for (const key of Object.keys(
              this.props.webSettings.profileLocations
            )) {
              if (key === item) {
                profileLocation = this.props.webSettings.profileLocations[key];
                break;
              }
            }
          }
          return (
            <Indicator
              key={deviceName}
              empty={empty}
              profileLocation={profileLocation}
              distance={distance}
            />
          );
        })}
      </ul>
    );
  }
}

export default Display;
